.Results {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: 1fr;
}

.Candidate {
    display: grid;
    grid-template-columns: minmax(150px, 2fr) 1fr 1fr;
    border: 2px solid grey;
    border-radius: 2px;
    justify-items: center;
    align-items: center;
}

.Candidate > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Candidate > p {
    font-size: 2rem; /* Optional: Set the font size */
}

.Won {
    color: green;
}

.Leading {
    color: #FFAA1D;
}


.banner_bkup {
  text-align: center;
  padding: 20px;
  background-color: yellow;
  transition: background-color 0.5s ease;
}

.banner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px;
  background-color: yellow;
  transition: background-color 0.5s ease;
  border: 2px solid blue;
}

.banner_reply > img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    justify-content: center;
}

.banner_reply {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.floating-banner {
  position: fixed;
  top: 50%;
  right: 0;
  background-color: light-blue;
  color: white;
  padding: 10px;
  animation: floatFromRight 20s linear infinite;
}

@keyframes floatFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
