/* Add your custom styling here */
.footer {
  background-color: #333;
  color: white;
  padding: 8px;
  text-align: center;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Add more styling as needed */
