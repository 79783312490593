/* Resetting default margin and padding for the entire page */
body, ul {
  margin: 0;
  padding: 0;
}

/* Basic styling for the navbar */
.navbar {
  background-color: inherit; /* Background color */
  overflow: hidden; /* Clear floats and create a new block formatting context */

  /* trying these -- ravi start */
  position: sticky;
  top: 0;
  /* trying these -- ravi end */
}

/* Style for the navbar links */
.navbar a {
  float: left; /* Float the list items to the left */
  display: block; /* Make the links block-level elements */
  color: white; /* Text color */
  text-align: center; /* Center the text within the list items */
  padding: 14px 16px; /* Padding for spacing */
  text-decoration: none; /* Remove underlines from links */
}

/* Change color on hover */
.navbar a:hover {
  background-color: #ddd; /* Background color on hover */
  color: black; /* Text color on hover */
}
