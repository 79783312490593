/* Add your custom styling here */
.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-content {
  text-align: left;
}

/* Add more styling as needed */
